<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div v-if="hasPermissionToRead">

            <b-card
              no-body
              class="mb-0"
            >
              <div class="mr-2 ml-2 mt-1 mb-1">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <div>
                      <b-form-group
                        label="Exibir"
                        label-for="idSearchBox"
                        class="mb-0"
                      >
                        <v-select
                          id="idSearchBox"
                          v-model="perPage"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions"
                          :clearable="false"
                          class="per-page-selector d-inline-block"
                        >
                          <span slot="no-options">Não há opções para essa quantidade.</span>
                        </v-select>
                      </b-form-group>
                    </div>
                    <b-form-input
                      v-model.lazy="searchQuery"
                      placeholder="Buscar"
                      type="text"
                      class="d-inline-block mt-2 ml-1"
                    />
                  </b-col>
                </b-row>
              </div>
              <b-table
                class="position-relative"
                primary-key="id"
                responsive
                show-empty
                striped
                hover
                empty-text="Não há categorias cadastradas."
                :items="data"
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                @row-clicked="edit"
              >
                <template #cell(field_id)="field">
                  <b-media vertical-align="center">
                    <b-link
                      class="font-weight-bold d-block text-nowrap"
                    >
                      #{{ field.item.id }}
                    </b-link>
                  </b-media>
                </template>

                <template #cell(field_name)="field">
                  <span class="font-weight-bold text-nowrap">
                    {{ field.item.name }}
                  </span>
                </template>

                <template #cell(field_actions)="field">
                  <div>
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-info"
                      size="md"
                      @click="showConfirmOrCancelDelete(field.item)"
                    >
                      <svg-icon
                        type="mdi"
                        size="18"
                        :path="mdiTrashCanOutline"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>

              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalColorName"
        ref="modalColorName"
        no-close-on-backdrop
        no-close-on-esc
        centered
        cancel-variant="outline-primary"
        :ok-disabled="invalid"
        @ok="handleSubmit(save)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Cadastro de Categoria
        </template>
        <template #modal-cancel>
          Cancelar
        </template>
        <template #modal-ok>
          Salvar
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="name"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="value.name"
                    name="category"
                    placeholder="Nome"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
    <div>
      <b-button
        id="addItemBtn"
        v-b-tooltip.hover
        v-b-modal.idModalColorName
        class="btn-icon rounded-circle m-3"
        variant="primary"
        size="md"
        :disabled="!hasPermissionToWrite"
        title="Adicionar categoria"
      >
        <svg-icon
          type="mdi"
          :path="mdiPlus"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BMedia,
  BLink,
  BModal,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiCogOutline,
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  required,
  confirmed,
} from '@validations'

import vSelect from 'vue-select'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BMedia,
    BLink,
    BModal,
    SpinLoader,
    SvgIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  filters: {
    truncate,
  },
  data() {
    const perPage = 10
    const totalItems = 0
    const currentPage = 1
    const searchQuery = ''
    const sortBy = 'name'
    const isSortDirDesc = false
    const perPageOptions = [10, 25, 50, 100]

    const tableColumns = [
      {
        key: 'field_id',
        label: 'ID',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_name',
        label: 'Nome',
      },
      {
        key: 'field_actions',
        label: '',
        thStyle: { width: '5%' },
      },
    ]

    const data = []

    const value = {
      name: '',
    }

    const isLoaded = false

    const isAdd = true

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    return {
      required,
      confirmed,
      refFormObserver,
      getValidationState,
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      perPageOptions,
      tableColumns,
      data,
      value,
      isLoaded,
      isAdd,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.supplier_settings.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.supplier_settings.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.supplier_settings.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.supplier_settings.can_delete
    },
  },
  watch: {
    perPage: 'fetchData',
    currentPage: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPage = 1
      this.searchQuery = value
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      syslic
        .supplier
        .category
        .fetchCategories({
          p: this.currentPage,
          page_size: this.perPage,
          q: this.searchQuery,
        })
        .then(response => {
          this.totalItems = response.data.count
          this.data = response.data.results

          this.isLoaded = true
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados de categorias.',
              text: 'Não foi possível ler os dados das categorias, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.isLoaded = true
        })
    },
    deleteCategory(item) {
      syslic
        .supplier
        .category
        .deleteCategory(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Categoria removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.data.splice(this.data.indexOf(item), 1)
        })
        .catch(error => {
          let errorVariant = 'danger'
          let textError = 'Não foi possível remover esta categoria, por favor entre em contato com o administrador do sistema.'
          if ('data' in error) {
            if (error.data.includes('Cannot delete category because they are referenced by one or more suppliers.')) {
              errorVariant = 'warning'
              textError = 'Esta categoria é referenciada em alguma licitação, por este motivo não será possível remove-la.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover categoria.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: errorVariant,
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você deseja remover esta categoria.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteCategory(item)
          }
        })
    },
    add(val) {
      syslic
        .supplier
        .category
        .addCategory(val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Categoria adicionada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(error => {
          let textError = 'Não foi possível adicionar esta categoria, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('duplicate key value violates unique constraint')) {
              textError = 'Já existe uma categoria com este nome, não é possivel adicionar uma categoria com nome repetido.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha a adicionar categoria.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    update(id, val) {
      syslic
        .supplier
        .category
        .updateCategory(id, val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Categoria atualizada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar esta categoria, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('duplicate key value violates unique constraint')) {
              textError = 'Já existe uma categoria com este nome, não é possivel atualizar uma categoria com nome repetido.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha a atualizar categoria.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    edit(item) {
      this.value = JSON.parse(JSON.stringify(item))
      this.colorSelector = item.color
      this.isAdd = false
      this.$refs.modalColorName.show()
    },
    save() {
      if (this.isAdd) {
        this.add(this.value)
      } else {
        this.update(this.value.id, this.value)
      }

      this.isAdd = true
    },
    cancel() {
      this.value = {
        name: '',
      }

      this.isAdd = true
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiCogOutline,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.smallField {
  width: 5px !important;
}

.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/base/core/colors/palette-variables.scss";

.card-title {
  color: $primary
}

#addItemBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

#idModalColorName .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;

  .btn{
    min-width: 107px;
  }
}
</style>
